@import '~antd/dist/antd.css';

body {
    
}

.container {
    margin: 12px;
    display: flex;
}

iframe {
    width: 209.9mm;
    min-width: 209.9mm;
    max-width: 209.9mm;
    height: 296.7mm;
    border: 1px solid #eee;
    box-shadow: 0 0 10px #eee;
    box-sizing: content-box;
}

main {
    flex: 1;
    margin-left: 8px;
}

.btn-wrapper button:not(:last-child) {
    margin-right: 8px;
}